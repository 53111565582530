.project {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;


  }
  
  .project h1 {
    margin-top: 200px ;
    color: #3e497a;
    font-size: 35px;

  }
  .project img {
    width: 400px;
    border-radius: 12px;
  }

  .project img:hover {
    cursor: pointer;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.6);
  }
  
  .project p {
    font-size: 40px;
    color: #3e497a;
    margin-bottom: 130px;
  }
  
   .project svg {
    /*font-size: 60px;
    color: #3e497a;
    position: relative;
     bottom : 280%;
    
    margin: 100px;  */
  } 